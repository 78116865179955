@import "../../globals.scss";
.header {
  p {
    font-weight: bold;
    font-size: 26px;
    color: black;
  }
  display: flex;
  align-items: center;
  height: 80px;
}
.submenu {
  background-color: white;
  text-wrap: nowrap;
  position: relative;
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 1px 21px 32px -6px rgba(0, 0, 0, 0.67);
  -webkit-box-shadow: 1px 21px 32px -6px rgba(0, 0, 0, 0.67);
  -moz-box-shadow: 1px 21px 32px -6px rgba(0, 0, 0, 0.67);
}
.submenu2 {
  background-color: white;
  text-wrap: nowrap;
  padding: 10px;
  border-radius: 5px;
  position: absolute;
  bottom: 0;
  left: 100%;
  box-shadow: 1px 21px 32px -6px rgba(0, 0, 0, 0.67);
  -webkit-box-shadow: 1px 21px 32px -6px rgba(0, 0, 0, 0.67);
  -moz-box-shadow: 1px 21px 32px -6px rgba(0, 0, 0, 0.67);
}
.submenu_container {
  position: absolute;
  top: 100%;
  left: -50%;
  z-index: 10;
}
.submenu:hover {
  cursor: pointer;
}
.navbar {
  background-color: $color-gray-2;
  &__count {
    position: absolute;
    top: 1px;
    right: 46px;
    font-style: normal;
    font-weight: bold !important;
    font-size: 15px !important;
    line-height: 14px;
  }

  &__menu {
    position: absolute;
    width: 360px !important;
    right: 0px;
    top: 80px;
    background-color: white;
    padding: 10px 0px;
    &--black {
      margin: 8px 0px;
      font-style: normal;
      font-weight: 900 !important;
      font-size: 30px !important;
      line-height: 35px;
      text-align: center;
      color: $color-green-dark !important;
    }
    &--black:hover {
      cursor: pointer;
    }
    &--red {
      margin: 8px 0px;
      font-style: normal;
      font-weight: 900 !important;
      font-size: 30px !important;
      line-height: 35px;
      text-align: center;
      color: red !important;
    }
    &--red:hover {
      cursor: pointer;
    }
  }
}
.navbar__title {
  font-weight: bold;
  color: black;
  font-size: 26px;
  line-height: 30px;
  text-decoration: none;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  @media (max-width: 1100px) {
    & {
      font-size: 12px;
      line-height: 14px;
    }
  }
}
.navbar__register {
  font-size: 23px !important;
  &--margin {
    margin-right: 32px !important;
  }
  background-color: $color-green-gray !important;
  color: white !important;
}
.navbar__logo {
  &:hover {
    cursor: pointer;
  }
  display: flex;
  align-items: center;
}
