@import "../../../globals.scss";

.adminHome{
  padding: 50px 75px;

  .adminHome__admin-title{
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: start;
  }

  .adminHome__welcome{
    margin: 0;
  }

  .adminHome__section{
    padding: 25px;

    &--right{
      margin-top: 120px;
      max-width: 385px;
    }
  }

  .adminHome__logo{
    width: 100%;
    height: auto;
  }

  .adminHome__box-container{
    display: flex;
    flex-wrap: wrap;
  }

  .adminHome__box{
    display: flex;
    width: 223px;
    height: 182px;
    background-color: $color-green-intense;
    border-radius: 29px;
    box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.4);
    align-items: center;
    justify-content: center;

    &:hover{
      background-color: $color-green-light;
    }
  }

}