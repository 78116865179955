@import "../../../globals.scss";
.register{
  &__container{
    max-width: 560px;
    background: $color-gray;
    box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.4);
    border-radius: 47px;
  }
  &__input{
    min-width: 400px;
    min-height: 40px;
    margin: 8px 0px;
    background-color: $color-gray-2;
    border-radius: 50px;
    box-shadow: 3px 5px 4px rgba(0, 0, 0, 0.25);
    padding: 0px 10px;
  }
  &__subtitle{
    padding-top: 8px;
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
  }
  &__error{
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 0px;
    color: red;
  }
  &__title{
    font-weight: bold;
    font-size: 26px;
    line-height: 30px;
    text-align: center;
  }
  &__select{
    &.MuiInput-underline::before{
      border-bottom: unset!important;
    }
    &.MuiInput-underline::after{
      border-bottom: unset!important;
    }
    background-color:  $color-gray-2;
    width: 170px;
    padding-left: 20px!important;
    padding-right: 0px!important;
    border-radius: 50px;
    box-shadow: 3px 5px 4px rgba(0, 0, 0, 0.25);
  }
  &__datepicker{
    width: 150px;
    box-shadow: 3px 5px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    padding-left: 10px!important;
    padding-right: 10px!important;
    background-color:  $color-gray-2;
  }
}