@import "../../../globals.scss";

.landing__img{
  width: 100%;
  background-image: url(../../../assets/background.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 30px 0;
}
.landing{
  &__title{
    max-width: 1440px;
    font-weight: 900;
    font-size: 53px;
    line-height: 62px;
    text-align: center;
    color: #FAFAFA;
    margin: 20px 0px 40px 0px;
    &--sub{
      max-width: 1350px;
      font-weight: 900;
      font-size: 60px;
      line-height: 70px;
      text-align: center;
      color: #FAFAFA;
      margin: 0px 0px;
    }
    &--green{
      max-width: 1440px;
      font-weight: 900;
      font-size: 60px;
      line-height: 70px;
      text-align: center;
      color: $color-green-intense;
      margin: 0px 0px;
    }

    @media(max-width: 768px){
      &,  &--sub,  &--green{
        font-size: 23px;
        line-height: 27px;
      }
    }
  }
  
  &__unetep{
    margin: 36px 0px;
    font-size: 50px;
    font-weight: 900;
    line-height: 59px;
    color:$color-green-dark;
    text-align: center;

    @media(max-width: 768px){
      font-size: 40px;
      line-height: 45px;
    }
  }

  &__card{
    height: 240px;
    max-height: 360px;
    border-radius: 20px;
    background-color:$color-shadow;
    padding: 0px 20px 25px 20px;
    p{
      color:$color-green-dark;
      font-weight: 900;
      font-size: 30px;
      line-height: 35px;
      text-align: center; 
      
    }
    &--white{
      p{
        color:$color-green-dark;
        font-weight: 900;
        font-size: 30px;
        line-height: 35px;
        text-align: center; 
      }
      border-radius: 20px;
      padding: 20px 20px;   
    }
  }
  &__button{
    span{
      font-weight: 900;
      font-size: 20px;
      line-height: 35px;
    }
    border-radius: 20px!important;
    height: 80px;
    width: 320px;
    background-color: $color-green-intense!important;
    color: white!important;
  }

  &__cards{
    padding: 30px 20px;
    .landing__comunity{
      width: fit-content;
      &--first{
        margin-right: 20px;
        @media(max-width: 768px){
          margin-right: 0;
          margin-bottom: 20px;
        }
      }
    }
  }
}


