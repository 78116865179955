@import "../../../globals.scss";
.list{
  padding:0 68px;
  @media(max-width: 768px){
    padding:0 20px;
  }
  &__title{
    font-style: normal;
    font-weight: 900;
    font-size: 70px;
    line-height: 82px;
    color: $color-green-dark;
    width: 100%;
    @media(max-width: 768px){
      font-size: 23px;
      line-height: 27px;
      text-align: center;
    }
  }
  &__subtitle{
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 35px;
    color: $color-green-dark;
    margin-left: 40px;

    @media(max-width: 768px){
      font-size: 16px;
      line-height: 19px;
    }

    &-container{
      flex-direction: row;
      @media(max-width: 768px){
        flex-direction: column;
      }
    }
  }
  &__what{
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 35px;
    text-align: center;
  }

  .list__table{

    width: 100%;
    border-spacing: 0;
    th, td{
      font-family: Roboto;
      font-style: normal;
      font-weight: 900;
      font-size: 30px;
      line-height: 35px;
      text-align: center;

      color: #000000;

      @media(max-width: 768px){
        font-size: 16px;
        line-height: 19px;
      }
    }
    tr:last-child{
      box-sizing: border-box;
      border-bottom: 7px solid $color-black-lines;
    }

    td{
      border-top: 3px solid $color-black-lines;
      border-bottom: 3px solid $color-black-lines;
      border-left: 6px solid $color-black-lines;
      box-sizing: border-box;

      &:last-child{
        border-right: 6px solid $color-black-lines;
      }
    }


    tr:first-child{
      td{
        border-top: 6px solid $color-black-lines;
      }
    }


    tr:last-child{
      td{
        border-bottom: 6px solid $color-black-lines;
      }
    }
    

    &--cross{
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        cursor: pointer;
      }
    }
  }

  &__endItemsTable{

    border-radius: 9px;
    box-sizing: border-box;
    border-left: 7px solid #112302;
    border-right: 7px solid #112302;
    border-bottom: 7px solid #112302;
    border-top: 7px solid #112302;

  }

  &__endItemTable{

    border-top: 7px solid #112302;
    padding: 8px;

    &--first{
      border-top: unset;
    }
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 35px;
    text-align: center;

    color: #000000;
  }
  
}