@import "../../globals.scss";
.footerText {
  p{
    font-size: 15px;
    color: #FAFAFA;
  }
}
.footer_contact{
  a{
    font-size: 15px;
    color: #FAFAFA;
  }
}
.footer__close{
    box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.25);
    background-color:#C4C4C4;
    cursor: pointer;
    width: 20px;
    height: 20px;
}
.footer__abeas{
    p{
        color: #193C1D;
    }
    &--text{
        padding: 0px 50px;
    }
    outline: 0;
    background-color: rgba(187, 204, 181, 0.95);
    border-radius: 29px;
    width: 800px;
    margin: auto;
    padding: 30px 0px;
}
.footer__privacy{
  background-color: $color-green-gray!important;
  color: white!important;
}
.footer__manual{
  text-decoration-line: unset!important;
  margin-left: 16px!important;
  background-color: $color-green-light!important;
  color: white!important;
}
.footer__modal{
    display: flex;
    align-items: center;
    justify-items: center;
}
.footer__social {
    padding: 0px 12px;
    width: 30px;
    justify-content: center;
    display: flex;
}
.footer{
    padding: 10px 0px;
    background-color: $color-green-dark;
    &_responsive {
        display: flex;
    }
}
@media (max-width: 600px) {
    .footerText {
        p{
            font-size: 12px;
        }
    }
    .footer_responsive{
        display: inline;
    }
}
