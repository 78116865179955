p{
  font-family: 'Roboto', sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

.main-container{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mt-1 { 
  margin-top: 8px;
}

.mt-2 { 
  margin-top: 16px;
}

.mt-3 { 
  margin-top: 24px;
}

.mt-4 { 
  margin-top: 32px;
}

.mt-5 { 
  margin-top: 40px;
}


.mb-1 { 
  margin-bottom: 8px;
}

.mb-2 { 
  margin-bottom: 16px;
}

.mb-3 { 
  margin-bottom: 24px;
}

.mb-4 { 
  margin-bottom: 32px;
}

.mb-5 { 
  margin-bottom: 40px;
}
.mr-1 { 
  margin-right: 8px;
}

.mr-2 { 
  margin-right: 16px;
}

.mr-3 { 
  margin-right: 24px;
}

.mr-4 { 
  margin-right: 32px;
}

.mr-5 { 
  margin-right: 40px;
}

.ml-1 { 
  margin-left: 8px;
}

.ml-2 { 
  margin-left: 16px;
}

.ml-3 { 
  margin-left: 24px;
}

.ml-4 { 
  margin-left: 32px;
}

.ml-5 { 
  margin-left: 40px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
