@import "../../globals.scss";

.wasteETable{
  border: 2px solid #000000;
  border-spacing: 0;
  width: 100%;
  th, td{
    border: 2px solid #000000;
  }

  th{
    background: $color-gray-2;
    text-align: center;
    img{
      cursor: pointer;
    }
  }

  .wasteETable__table-head{
    display: flex;
    justify-content: space-between;
    padding: 0 3px;
  }

  td{
    background: $color-gray-3;
  }

  .wasteETable__table-image{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}