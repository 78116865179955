@import "../../../globals.scss";
.nosotros{

  padding: 0 20px;

  &__title{
    font-weight: 900;
    font-size: 60px;
    line-height: 70px;
    text-align: center;
    color: $color-green-dark;
    margin-top: 20px;
    margin-bottom: 0px;
  }
  &__subtitle{
    font-weight: 900;
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    color: black;
  }
  &__subtitle2{
    font-weight: 900;
    font-size: 30px;
    line-height: 35px;
    text-align: left;
    color: black;
  }
  &__face{
    padding: 15px 33px;
    width: 140px;
  }
  &__name{
    margin: 0px 0px;
    font-weight: bold;
    font-size: 26px;
    line-height: 30px;
    text-align: center;
  }
  &__conoce{
    margin-right: 32px;
    font-weight: 900;
    font-size: 50px;
    line-height: 59px;
    text-align: center;
    color:$color-green-dark;
  }

  &__img{
    width: 100%;
    height: auto;

  }
}