@import "../../globals.scss";

.specialCombobox{

  width: 100%;

  .specialCombobox__button{
    background: $color-green-light;
    box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.4);
    border-radius: 16px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 7px 22px;

    &--info{
      display: flex;

      align-items: center;
    }

    &--label{
      font-family: Roboto;
      font-style: normal;
      font-weight: 900;
      font-size: 30px;
      line-height: 35px;
      color: #FFFFFF;

      margin: 0px;
      margin-left: 25px;

      @media(max-width: 768px){
        font-size: 16px;
        line-height: 19px;
      }
    }

    &--triangle{
      width: 30px;
      height: 38px;

      @media(max-width: 768px){
        width: 16px;
        height: 19px;
      }
    }

    &--img{
      height: 50px;
      width: 50px;
      @media(max-width: 768px){
        height: 25px;
        width: 25px;
      }
    }

    &:hover{
      background-color: $color-green-gray;
      cursor: pointer;
    }
  }

  .specialCombobox__options{
    margin: 10px 0;
    
    overflow: hidden;

    border: 3px solid #1C9C38;
    box-sizing: border-box;
    border-radius: 16px;

    width: 100%;

    &--option{
      font-family: Roboto;
      font-style: normal;
      font-weight: 900;
      font-size: 30px;
      line-height: 35px;

      padding: 5px 0;
      border-bottom: 1px solid $color-black-lines;

      @media(max-width: 768px){
        font-size: 16px;
        line-height: 19px;
      }

      &:hover{
        background-color: $color-gray;
      }
    }

    &--scroll{
      padding: 18px 30px;
      max-height: 170px;
      overflow-y: auto;
    }
    
  }

}