@import "../../../globals.scss";

.adminActualizar{
  padding: 50px 100px;  

  .adminActualizar__profile{
    border-radius: 50%;
    height: 149px;
    width: auto;
  }

  .adminActualizar__table{
    border: 2px solid #000000;
    border-spacing: 0;
    width: 100%;
    
    th, td{
      border: 2px solid #000000;
      padding: 10px 20px;
    }

    .adminActualizar__table--font{
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 23px;
      line-height: 27px;
    }

    .adminActualizar__table--gray{
      background: $color-gray-2;
      width: 25%;
    }

    .adminActualizar__table--input{
      width: 100%;
    }

    .adminActualizar__table--icon{
      width: 1%;
      white-space: nowrap;

      &:hover{
        background: $color-gray-2;
        cursor: pointer;
      }
    }
  }

  .adminActualizar__submit-button{
    background-color: $color-green-intense;
    box-shadow: 3px 5px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    padding: 4px 50px;
    margin-top: 50px;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 27px;
    text-align: center;

    &:hover{
      background-color: $color-green-light;
      cursor: pointer;
    }
  }
}