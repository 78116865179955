@import "../../../globals.scss";
.form{
  &__add{
    background: $color-green-light;
    box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.4);
    border-radius: 16px;
    padding: 12px 26px;
    text-align: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 35px;
    color:white;
    &:hover{
      cursor: pointer;
      background: $color-green-gray;
    }
  }
  &__title{
    margin-top: 40px;
    font-weight: 900;
    font-size: 70px;
    line-height: 82px;
    text-align: center;
    color: $color-green-dark;
    @media(max-width: 768px){
      font-size: 23px;
      line-height: 27px;
    }
    
  }
  &__disp{
    box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.4);
    border-radius: 16px;
    background-color: $color-green-light;
    width: 100%;
    padding: 7px 22px;
    &:hover{
      background-color: $color-green-gray;
      cursor: pointer;
    }
    &--icon{
      width: 50px;
      height: 50px;

      @media(max-width: 768px){
        height: 25px;
        width: 25px;
      }
    }
    &--text{
      margin-top: 0px;
      margin-bottom: 0px;
      margin-left: 25px;
      font-weight: 900;
      font-size: 30px;
      line-height: 35px;
      color: #ffffff;

      @media(max-width: 768px){
        font-size: 16px;
        line-height: 19px;
      }
      
    }
  }
  &__item{
    box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.4);
    border-radius: 16px;
    background-color: $color-green-light;
    width: 100%;
    margin-bottom: 30px;
    padding: 24px 0;
    &--icon{
      width: 70px;
      height: 70px;
      margin-left: 32px;
    }
    
    &--text{
      margin: 0px;
      margin-left: 32px;
      font-weight: 900;
      font-size: 30px;
      line-height: 35px;
      color: #ffffff;
      max-width: 325px;
    }

    @media(max-width: 768px){
      padding: 10px 0;
      margin-bottom: 15px;
      &--text{
        font-size: 16px;
        line-height: 19px;
      }

      &--icon{
        width: 40px;
        height: 40px;
        margin-left: 32px;
      }
      
    }
  }
  &__item:hover{
    background-color: $color-green-dark;
    cursor: pointer;
  }

  &__upload-photo{
    max-width: 300px;
    max-height: 300px;
    width: auto;
    height: auto;

    border-radius: 10px;
  }

  &__combobox-container{
    max-width: 700px;
    width: 100%;
    margin: 10px 0;
  }

  
}