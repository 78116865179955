@import "../../../globals.scss";
.profile{
  &__input{
    min-width: 600px;
    min-height: 40px;
    margin: 8px 0px;
    background-color: $color-gray-2;
    border-radius: 50px;
    box-shadow: 3px 5px 4px rgba(0, 0, 0, 0.25);
    padding: 0px 10px;
  }
 
  &__select{
    &.MuiInput-underline::before{
      border-bottom: unset!important;
    }
    &.MuiInput-underline::after{
      border-bottom: unset!important;
    }
    background-color:  $color-gray-2;
    width: 600px;
    padding-left: 20px!important;
    padding-right: 0px!important;
    border-radius: 50px;
    box-shadow: 3px 5px 4px rgba(0, 0, 0, 0.25);
  }
  &__title{
    font-weight: 900;
    font-size: 40px;
    line-height: 47px;
    color: $color-green-dark;
  }
  &__secondtitle{
    margin-top: 60px;
    margin-bottom: 60px;
    font-weight: 900;
    font-size: 70px;
    line-height: 82px;
    color: $color-green-dark;
  }
  
  &__subtitle{
    margin-bottom: 0px;
    font-weight: bold;
    font-size: 26px;
    line-height: 30px;
    color: black;
  }
  &__collectortitle{
    font-weight: 900;
    font-size: 30px;
    line-height: 35px;
    color: black;
  }
  &__collectorname{
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 30px;
    text-align: center;
  }
  &__table{
    border: 2px solid #000000;
    border-spacing: 0;
    width: 100%;
    
    th, td{
      border: 2px solid #000000;
      padding: 10px 20px;
    }
  }
  &__table--font{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 27px;
  }
  &__table--gray{
    background: $color-gray-2;
    width: 25%;
  }
  &__photo{
    width: 200px;
    height: 200px;
    border-radius: 50%;
  }
  &__actualiza{
    margin: 0 16px;
  }
  &__actualiza:hover{
    cursor: pointer;
  }
}