@import "../../globals.scss";

.imageInput{
  position: relative;
  width: 100%;
  &:hover{
    cursor: pointer;
  }
  .imageInput__input{
    display: none;
  }
}