@import "../../../globals.scss";
.adminCreacion{
  padding: 50px 100px;  


  .adminCreacion__form-container{
    background-color: $color-gray;
    box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.4);
    border-radius: 47px;
    margin: 25px 0;
    padding: 30px 60px;
  }

  .adminCreacion__form-title{
    text-align: start;
    width: fit-content;
    margin: 0;
    margin-bottom: 15px;
  }

  .adminCreacion__column{
    display: flex;
    flex-direction: column;
    width: 45%;
  }

  .adminCreacion__input{
    background-color: $color-gray-2;
    box-shadow: 3px 5px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    padding: 5px;

    width: 100%;

    font-size: 25px;

    border-bottom: unset;
    &::before, &:hover, &:hover:not(.Mui-disabled):before, &::after{
      border-bottom: unset;
    }

    
  }

  .adminCreacion__label{
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 6px;
  }

  .adminCreacion__submit-button{
    background-color: $color-green-intense;
    box-shadow: 3px 5px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    padding: 4px 50px;
    margin-top: 50px;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 27px;
    text-align: center;

    &:hover{
      background-color: $color-green-light;
      cursor: pointer;
    }
  }
}