@import "../../globals.scss";

.profileBase{
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 500px;
  align-items: center;
  .profileBase__title{
    margin-top: 30px;
    font-weight: 900;
    font-size: 70px;
    line-height: 82px;
    color: $color-green-dark;
  }
  .profileBase__img--prof{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .profileBase__img--container{
    position: relative;
  }

  .profileBase__img--change{
    position: absolute;
    top: -10px;
    right: -10px;

    // &:hover, img:hover{
    //   cursor: pointer;
    // }
    // input{
    //   display: none;
    // }
  }

  .profileBase__photo{
    width: 180px;
    height: 180px;
    border-radius: 50%;
  }

  .profileBase__titlename{
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 35px;
    color: black;
  }

  .profileBase__inputnew{
    display: flex;
    box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.4);
    border-radius: 30px;
    align-items: center;
    justify-content: center;
    padding: 8px 18px;
    background-color: $color-green-light;
    max-width: 330px;
    height: fit-content;
    margin-top: 150px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 35px;
    text-align: center;

    color: #FFFFFF;

    &:hover{
      background-color: $color-green-dark;
    }
  }
}