$color-green-dark: #193C1D;
$color-green-light: #1C9C38;
$color-green-intense: #50CE15;
$color-green-gray:  #4E6247;
$color-gray:  #C4C4C4;
$color-gray-2:  #F2F2F2;
$color-gray-3:  #FCFCFC;
$color-shadow: #E0E0E0;
$color-black-buttons: #333333;
$color-black-lines: #112302;

.mt-1 { 
  margin-top: 8px;
}

.mt-2 { 
  margin-top: 16px;
}

.mt-3 { 
  margin-top: 24px;
}

.mt-4 { 
  margin-top: 32px;
}

.mt-5 { 
  margin-top: 40px;
}


.mb-1 { 
  margin-bottom: 8px;
}

.mb-2 { 
  margin-bottom: 16px;
}

.mb-3 { 
  margin-bottom: 24px;
}

.mb-4 { 
  margin-bottom: 32px;
}

.mb-5 { 
  margin-bottom: 40px;
}
.mr-1 { 
  margin-right: 8px;
}

.mr-2 { 
  margin-right: 16px;
}

.mr-3 { 
  margin-right: 24px;
}

.mr-4 { 
  margin-right: 32px;
}

.mr-5 { 
  margin-right: 40px;
}

.ml-1 { 
  margin-left: 8px;
}

.ml-2 { 
  margin-left: 16px;
}

.ml-3 { 
  margin-left: 24px;
}

.ml-4 { 
  margin-left: 32px;
}

.ml-5 { 
  margin-left: 40px;
}


.title-1{
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: 70px;
  line-height: 82px;
  text-align: center;
}

.title-5{
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
}

.green-button{
  display: flex;
  box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  padding: 0 50px;
  background-color: $color-green-intense;

  height: fit-content;

  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 35px;
  text-align: center;

  &:hover{
    cursor: pointer;
    background-color: $color-green-light;
  }
}

.gray-button-login{
  display: flex;
  box-shadow: 3px 5px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  padding: 5px 20px;
  background-color: $color-gray-2;

  height: fit-content;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 27px;
  text-align: center;
  color: black;

  &:hover{
    cursor: pointer;
    background-color: $color-gray-3;
  }
}

.green-button-register{
  display: flex;
  box-shadow: 3px 5px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  padding: 5px 40px;
  background-color: $color-green-intense;

  height: fit-content;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 27px;
  text-align: center;
  color: black;

  &:hover{
    cursor: pointer;
    background-color: $color-green-light;
  }
}

.black-button-register{
  display: flex;
  box-shadow: 3px 5px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  padding: 10px 30px;
  background-color: $color-black-buttons;
  width: 70px;
  height: fit-content;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 27px;
  text-align: center;
  color: $color-green-intense;

  &:hover{
    cursor: pointer;
    background-color: $color-green-gray;
  }
}

.black-button{
  display: flex;
  box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  padding: 0 50px;
  background-color: $color-black-buttons;

  height: fit-content;

  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 35px;
  text-align: center;

  color: #FFFFFF;

  &:hover{
    cursor: pointer;
    background-color: #000000;
  }
}
