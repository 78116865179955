@import "../../globals.scss";

.showImgs{
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .showImgs__img{
    max-width: 70vw;
    max-height: 70vh;
    width: auto;
    height: auto;
  }

  .showImgs__arrow{
    cursor: pointer;
    width: 30px;
    height: auto;
    position: absolute;
    top: 50%;

    &--rigth{
      right: 50px;
    }
    &--left{
      left: 50px;
      transform: rotate(180deg);
    }
  }
}

.showImgs__eye:hover{
  cursor: pointer;
}