@import "../../../globals.scss";
.login{
  padding: 20px;
  .login__logodiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
  }
  &__logo{
    padding: 32px 0px ;
    width: 400px;
  }
  &__logotext{
    max-width: 460px;
    text-align: justify;
    font-weight: bold;
    font-size: 26px;
    line-height: 30px;
    text-align: center;
    margin: 0px 0px;
    &--green{
      color: $color-green-light;
    }
  }
  &__background{
    border-radius: 50px!important;
    background-color: $color-gray!important;
  }
  &__input{
    min-width: 400px;
    margin: 8px 0px;
    background-color: white;
    border-radius: 50px;
    padding: 0px 10px;
  }
  &__formtitle{
    text-align: center;
    font-weight: bold;
    font-size: 26px;
    line-height: 30px;
    color: black;
  }
  &__formsubtitle{
    margin-bottom: 8px;
    width: 100px;
    align-items: center;
    display: flex;
    min-height: 53px;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 27px;
  }
}