@import "../../../globals.scss";
.adminEntregaCoordinar{
  padding: 50px 100px;  

  .adminEntregaCoordinar__table{
    border: 2px solid #000000;
    border-spacing: 0;
    width: 100%;
    
    th, td{
      border: 2px solid #000000;
      padding: 10px 20px;
    }
  }

  .adminEntregaCoordinar__table--font{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 27px;
  }

  .adminEntregaCoordinar__table--gray{
    background: $color-gray-2;
    width: 25%;
  }

  

  .adminEntregaCoordinar__table--input{
    width: 100%;
  }

  .adminEntregaCoordinar__table--icon{
    width: 1%;
    white-space: nowrap;

    &:hover{
      background: $color-gray-2;
      cursor: pointer;
    }
  }

  .adminEntregaCoordinar__table--photos{
    display: flex;
    flex-wrap: wrap;
  }

  .adminEntregaCoordinar__table--img{
    height: 100px;
    width: auto; 
    margin: 0 10px;
  }

  .adminEntregaCoordinar__back-button{
    margin: 20px 0;
  }

  .adminEntregaCoordinar__reciclador-container{
    margin-top: 100px;
  }

  .adminEntregaCoordinar__input{
    .MuiInputBase-root{
      width: 100%;
    }

    .MuiInput-underline{
      &, &:hover, &::before, &::after, &:not(.mui-disabled):before{
        border-bottom: unset;
      }
    }

    .MuiSelect-select{
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 23px;
      line-height: 27px;
    }
  }

  .adminEntregaCoordinar__submit-button{
    background-color: $color-green-intense;
    box-shadow: 3px 5px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    padding: 4px 50px;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 27px;
    text-align: center;

    &:hover{
      background-color: $color-green-light;
      cursor: pointer;
    }
  }

  .adminEntregaCoordinar__finish-entrega{
    background: $color-green-light;
    box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.4);
    border-radius: 16px;

    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 35px;
    text-align: center;

    color: #FFFFFF;

    padding: 20px;

    &:hover{
      cursor: pointer;
      background-color: $color-green-gray;
    }
  }
  
}