@import "../../../globals.scss";
.contactenos{
  &__text{
    margin-left: 146px;
    max-width: 590px;
    font-weight: 900;
    font-size: 30px;
    line-height: 35px;
  }
  &__input{
    input{
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 26px;
      line-height: 30px;
    }
    min-width: 400px;
    margin: 8px 0px;
    background-color: white;
    border-radius: 50px;
    padding: 0px 10px;
    box-shadow: 3px 5px 4px rgba(0, 0, 0, 0.25);

    &--comment{
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 26px;
      line-height: 30px;
      min-width: 380px;
      min-height: 200px;
      margin: 8px 0px;
      background-color: white;
      border-radius: 16px;
      border-color: white;
      padding: 0px 10px;
      box-shadow: 3px 5px 4px rgba(0, 0, 0, 0.25);
    }
  }
  &__button{
    margin-top: 16px;
  }
}
