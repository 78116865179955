@import "../../globals.scss";

.personalizedInput{
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 23px;
  
  width: 200px;
  height: 35px;
  
  background-position: 160px 3px;
  background-repeat: no-repeat;
  background-size: 25px auto;

  background-color: $color-gray-2;
  border: 2px solid #000000;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}